import { isArray } from 'lodash'
import Rest from '../Rest'

export default class PhysicalQuestionnaireService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/physical_questionnaires'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  addParticipants(physicalQuestionnaireId, data) {
    return this.post(`${physicalQuestionnaireId}/participants`, data).then(
      (data) => {
        return data
      }
    )
  }

  removeParticipant(physicalQuestionnaireId, participantId) {
    return this.delete(`${physicalQuestionnaireId}/participant/${participantId}`)
  }

  getParticipantCorrection(participantId) {
    return this.get(`participants/${participantId}/correction`)
  }

  getParticipantLogs(participantId) {
    return this.get(`participants/${participantId}/logs`)
  }

  getLogMessages(logId) {
    return this.get(`logs/${logId}/messages`)
  }

  selectAllParticipants(physicalQuestionnaireId) {
    return this.get(`${physicalQuestionnaireId}/participants/selection`)
  }

  downloadSheets(physicalQuestionnaireId, participants) {
    const params: any = {}
    if (isArray(participants) && participants.length > 0) params.selection = participants
    return this.get(`${physicalQuestionnaireId}/participants/questionnaires`, params)
  }

  downloadAnswerSheets(physicalQuestionnaireId, participants) {
    const params: any = {}
    if (isArray(participants) && participants.length > 0) params.selection = participants
    return this.get(`${physicalQuestionnaireId}/participants/answer_sheets`, params)
  }


}
