
<template>
  <div>
    <div v-permission="'physical_questionnaires.create'" class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{$t('AddNew')}}</span>
      </div>
    </div>
    <Grid
      :key="key"
      :service="service"
      route_name="physical_questionnaires"
      :column_formats="{
        closed_at: (val) => val !== null ? $utils.format.date(val) : '-',
        participant_count: (val) => parseInt(val) > 0 ? val : 'Nenhum'
      }"
    ></Grid>
  </div>
</template>


<script>
import PhysicalQuestionnaireService from '@/services/api/PhysicalQuestionnaireService'

export default {
  data: () => ({
    selected: [],
    service: null,
    key: 0
  }),
  watch: {
  },
  computed: {
  },
  methods: {
    addNew() {
      this.$router.push('/physical_questionnaires/create')
    }
  },
  beforeMount() {
    this.service = PhysicalQuestionnaireService.build(this.$vs)
  }
}
</script>
